.groups-card {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
  margin-bottom: 24px;
  gap: 16px;
}

.sync-groups-arrow-button {
  border: none;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.groups-table-title {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;

  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #343232;
}

.logs-sync-button {
  width: 120px;
  height: 40px;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
}

.logs-sync-button:not(:disabled):hover {
  border: 2px solid #A4A3A3;
}

.logs-sync-button::before {
  width: 15px;
  height: 18px;
  position: relative;
  top: 3px;
  margin-right: 6px;
  content: url('../../images/icons/Sync.svg');
}

.logs-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 25px;
}

.tr-grid-groups th,
.tr-grid-groups td {
  overflow: visible;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.table-th-logs {
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
}

.tr-grid-groups td {
  padding: 7px 7px 7px 12px;
}

.tr-grid-groups th {
  position: -webkit-sticky;
  position: sticky;
  top: 63px;
  padding: 11px 0 11px 12px;
  text-align: left;
  background: #fff;
  z-index: 3;
}

.tr-groups,
.tr-groups-csv {
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 0;
}

.tr-groups td,
.tr-groups-csv td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.tr-groups td span:nth-of-type(2),
.tr-groups-csv td span:nth-of-type(2) {
  color: #28B865;
}

.tr-groups td span:nth-of-type(3),
.tr-groups-csv td span:nth-of-type(3) {
  color: #DE2C37;
  ;
}

.tr-groups td:nth-of-type(3),
.tr-groups-csv td:nth-of-type(4) {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.not-clickable-groups button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.not-clickable-groups button:hover {
  color: #9D1B1F
}

.not-clickable-groups button.ascending::after {
  content: '\2193';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.not-clickable-groups button.descending::after {
  content: '\2191';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.not-clickable-groups th,
.not-clickable-groups-details th {
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
  transition: border-color .2s;
}

.not-clickable-groups th:last-of-type {
  text-align: center;
}

.clickable-row-logs td {
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
  transition: background-color .2s;
}

.logsnavbar {
  display: flex;
  justify-content: center;
  transition: transform 0.1s;
  margin: 0 auto;
}

.logsnavbar-close path {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.logs-details {
  display: block;
}

.logs-details-table-tr {
  position: sticky;
  top: 142px;
  background-color: #F3F3F3;
  z-index: 2;
}

.logs-details-error-description,
.logs-details-created-description {
  padding: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
  background-color: #F3F3F3;
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
  border: none;
}

.logs-details-created-description td::before {
  content: url('../../images/icons/Check Circle Green.svg');
  margin-right: 4px;
  position: relative;
  top: 3px
}

.logs-details-created-description td {
  padding: 12px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
}

.logs-details-error-description td {
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
}

.logs-details-created-description td {
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
}

.logs-details-created-description td {
  padding: 12px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
}

.logs-details-created-description td {
  padding: 12px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
}

.logs-details-error-description td::before {
  content: url('../../images/icons/Alert triangle 2.svg');
  margin-right: 4px;
  position: relative;
  top: 3px
}

.logs-details-created-description td::before {
  content: url('../../images/icons/Check\ Circle\ Green.svg');
  margin-right: 4px;
  position: relative;
  top: 3px
}

.logs-button-open {
  display: block;
  background: none;
  border: none;
  margin: 0 auto;
  padding: 0;
  cursor: pointer;
  transition: transform 0.1s linear;
}

.logs-button-open:disabled {
  opacity: 0.6;
}

.logs-button-open:not(:disabled):hover {
  transform: scale(1.08);
}

.logs-details-table {
  position: relative;
  top: -3px;
  width: 100%;
  height: 240px;
  background-color: #F3F3F3;
  border-collapse: collapse;
}

.logs-details-table th {
  padding: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.logs-details-table td,
.logs-details-table tr {
  z-index: 2;
  border-width: 1px;
  border-style: solid;
  border-color: #E6E6E6;
}

.logs-details-table td {
  padding: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.groups-log-details {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.errors-table {
  display: table;
}

.icon-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.table-group-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.groups-table-option {
  display: flex;
  align-items: center;
  border: none;
  background-color: #FFFFFF;
  justify-content: center;
  align-items: center;
  padding: 8px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  cursor: pointer;
}

.groups-table-option:hover {
  background-color: #F3F3F3;
  border-radius: 50%;
  aspect-ratio: 1;
}

.active-list::after {
  margin-left: 4px;
  position: relative;
  background-image: url('../../images/icons/up-arrow.svg');
  background-size: 10px 10px;
  display: inline-block;
  width: 10px;
  height: 10px;
  content: "";
}

.td-summary {
  background-color: #F3F3F3;
}

.log-groups-count {
  color: #B72025;
  font-size: 12px;
}

.all-languages-selected-bar {
  display: flex;
  justify-content: space-between;
  margin: 8px 8px 8px 0;
  align-items: center;
}

.all-lang-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.lang-select {
  position: relative;
  width: 60%;
  z-index: 4;
}

.sync-groups-select {
  z-index: 5;
  width: 320px;
}

.lang-select-in-row {
  width: 80%;
  z-index: 4;
}

.group-lang-buttons-container {
  display: flex;
  flex-direction: column;
}
.group-lang-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mapping-link {
  flex-shrink: 0;
}

.lang-select::before {
  width: 23px;
  height: 23px;
  position: absolute;
  top: 25%;
  left: 9px;
  content: url('../../images/icons/world-svgrepo-com-blue.svg');
}

.lang-select-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  gap: 8px
}

.submit-button {
  padding: 12px 25px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #B72025;
  border-radius: 4px;
  border: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #9D1B1F;
}

.submit-button:active {
  background-color: #B72025;
}

.submit-button:disabled {
  background-color: #EFB7B6;
}

.sync-groups-container-empty {
  margin-bottom: 8px;
}

.group-link a,
.group-link button {
  position: relative;
  color: #B72025;
  font-weight: 600;
  text-decoration: none;
  border: none;
  background-color: #FFFFFF;
}

.group-link a:hover,
.group-link button:hover {
  position: relative;
  color: #B72025;
  font-weight: 600;
  text-decoration-line: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
}

.sync-groups-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 8px;
}

.no-groups-message {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.sync-groups-select-icon::before {
  width: 11px;
  height: 8px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.sync-groups-select-icon-language::before {
  content: url('../../images/icons/world-svgrepo-com.svg');
}

.sync-groups-select-icon-tenant::before {
  content: url('../../images/icons/noun-tenant.svg');
}

.sync-groups-select-icon-group::before {
  content: url('../../images/icons/Groups.svg');
}

.sync-group-select {
  position: relative;
}

.input-search-groups {
  position: relative;
  width: max-content;
  display: flex;
  flex-direction: column;
}

.input-search-groups > input {
  width: 300px;
  padding: 6px 30px 6px 30px;
  border-radius: 4px;
  border: 1px solid #D6D6D6;
  background-color: #FFF;
  color: #343232;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.28px;
  transition: all .1s ease-in-out;
}

.input-search-groups::before {
  width: 14px;
  height: 12px;
  position: absolute;
  top: 34px;
  left: 10px;
  content: url('../../images/icons/Search.svg');
}

.input-search-groups > input::placeholder {
  font-size: 0.9em;
  color: #A4A3A3;
}

.input-search-groups > input:not(:disabled):not(:focus):hover {
  box-shadow: 0 0 3px 0.5px #D6D6D6;
}

.input-search-groups > input:focus {
  box-shadow: 0 0 3px 2px #D6D6D6;
  outline: none;
  border-color: #d3d3d3;
}

.input-search-groups > i {
  display: inline-block;
  position: absolute;
  right: 0;
  width: 30px;
  height: 34px;
  opacity: .5;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.input-search-groups > i::before {
  width: 18px;
  position: absolute;
  top: 34px;
  right: 10px;
  content: url('../../images/icons/Cross.svg');
}

.secondary-info {
  min-height: 40px;
  min-width: 250px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #C2C1C1;
}

.all-groups-secondary-button {
  height: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  background: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #B72025;
}

.all-groups-secondary-button:not(:disabled):hover {
  background-color: #B72025;
  color: #FFFFFF;
}

.all-groups-secondary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.all-groups-button-active::after {
  width: 8px;
  max-height: 8px;
  position: relative;
  margin-left: 4px;
  top: 3px;
  content: url('./../../images/icons/expand-collapse.svg');
}

.all-groups-button-active::after {
  width: 8px;
  max-height: 8px;
  position: relative;
  margin: 0 6px 0 4px;
  top: -4px;
  content: url('./../../images/icons/expand-collapse.svg');
}

.all-groups-button-active {
  display: flex;
  align-items: center;
  border: 1px solid #A4A3A3;
  height: 24px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
  margin-right: 8px;
  background-color: #5D5B5B;
}

.all-groups-button {
  display: flex;
  align-items: center;
  border: 1px solid #A4A3A3;
  height: 24px;
  border-radius: 4px;
  background-color: #F3F3F3;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  margin-right: 8px;
  cursor: pointer;
  transition: border-color .1s ease-in-out;
}

.all-groups-button:hover {
  border-color: #5D5B5B;
}

.all-groups-button::after {
  width: 8px;
  height: 8px;
  position: relative;
  margin: 0 6px 0 4px;
  top: -4px;
  content: url('./../../images/icons/sort.svg');
}

.all-groups-emails-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;
}

.group-container {
  height: 24px;
  border: 1px solid #A4A3A3;
  padding: 4px;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  display: flex;
  align-items: center;
  gap: 6px;
}
.all-groups-container {
  height: 24px;
  padding: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  display: flex;
  align-items: center;
  gap: 6px;
}

.all-groups-container-img {
  height: 18px;
  margin: 10px 4px 0 4px;
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.all-groups-container-img:hover {
  transform: scale(1.1);
}
