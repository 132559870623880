.react-select {
  width: max-content;
  overflow-x: hidden;
}

.react-select-option {
  display: block;
  font-size: 12px;
  color: #343232;
  padding: 5px 10px;
  width: 100%;
  min-width: max-content;
  user-select: none;
  box-sizing: border-box;
  border-radius: 4px;
}
.react-select-option:hover {
  background-color: #FBEDED;
  cursor: pointer;
}
.react-select-option--selected {
  background-color: #ffdede;
  color: #FFFFFF
}
.react-select-option--selected:hover {
  background-color: #ffdede;
}

.option-select-default {
  width: 320px;
  min-height: 40px;
}
.option-select-category {
  min-height: 40px;
}

.react-select-option-label {
  color: #343232;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
}

.react-select-caption {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #A4A3A3;
}

.all-users-multiselect {
  width: 320px;
  min-height: 40px;
}

.all-users-label,
.department-label,
.location-label,
.group-label,
.category-label {
  position: relative;
  padding: 2px 0 2px 18px;
}

.category-label {
  text-align: left;
  left: -20px;
}

.all-users-label::before {
  width: 11px;
  height: 8px;
  position: absolute;
  top: 3px;
  left: 0;
  content: url('../../images/icons/Envelope Small.svg');
}
.group-label::before {
  width: 11px;
  height: 8px;
  position: absolute;
  top: 3px;
  left: 0;
  content: url('../../images/icons/Groups.svg');
}

.department-select::before {
  width: 14px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 13px;
  content: url('../../images/icons/department-1.svg');
}

.location-select::before {
  width: 14px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 13px;
  content: url('../../images/icons/location-1.svg');
}

.department-label::before {
  display: block;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 2px;
  left: 0;
  content: url('../../images/icons/department-6.svg');
}

.location-label::before {
  display: block;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 2px;
  left: 0;
  margin-right: 5px;
  content: url('../../images/icons/address-location-icon.svg');
}


.category-label::before {
  position: relative;
  top: 4px;
  margin-right: 5px;
  content: url('../../images/icons/Education-2.svg');
}

.department-label-all::before,
.location-label-all::before,
.category-label-all::before {
  content: url('../../images/icons/all.svg');
}

.category-label-all {
  font-weight: 500;
}

.category-label-all::before {
  top: 3px;
}

.action-type-label,
.email-type-label,
.company-label {
  padding: 2px 0;
}

.executive-input-field-analytics-tenant::before {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 8px;
  left: 14px;
  content: url('../../images/icons/Layer Group.svg');
  z-index: 2;
}

.executive-input-field-analytics-group::before {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 8px;
  left: 14px;
  content: url('../../images/icons/Groups.svg');
  z-index: 2;
}

.tenant-option-label-azure::before,
.tenant-option-label-google::before,
.tenant-option-label-csv::before {
  display: inline-block;
  transform: translateY(3px);
  margin-right: 4px;
}

.tenant-option-label-azure::before {
  content: url('../../images/icons/Azure.svg');
}

.tenant-option-label-google::before {
  content: url('../../images/icons/Google\ \(colors\).svg');
}
.tenant-option-label-csv::before {
  content: url('../../images/icons/File\ Red.svg');
  transform: translateY(2px);
}

.date-range-select-label .react-select__control {
  padding: 0;
}

.date-range-select-label {
  position: relative;
}

.date-range-select-label--enabled .react-select__control::after {
  display: block;
  position: absolute;
  content: url('../../images/icons/Calendar.svg');
  width: 13px;
  height: 13px;
  top: 9px;
  right: 45px;
}
