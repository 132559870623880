.tr-history td {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.tr-history th:last-of-type {
  margin: 0;
}

.tr-history {
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 0;
}

.history-card {
  min-width: 820px;
  width: 100%;
  min-width: 1200px;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.history-description {
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.history-action {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.history-open {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.history-action-title {
  font-weight: 800;
  font-size: 20px;
  line-height: 26px;
  color: #343232;
}

.history-table {
  margin: 16px 0 0 0;
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns:
    minmax(120px, 1fr)
    minmax(200px, 1fr)
    minmax(100px, 1fr)
    minmax(110px, 1fr)
    minmax(110px, 1fr)
    minmax(100px, 1fr)
    minmax(160px, 1fr);
}

.table-th-history,
.table-body-history,
.tr-grid-history {
  display: contents;
}

.tr-grid-history th,
.tr-grid-history td {
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.tr-grid-history td {
  padding: 7px 0 7px 12px;
}

.tr-grid-history th {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  padding: 11px 0 11px 12px;
  text-align: left;
  background: #fff;
}

.not-clickable-history th {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
  transition: border-color .2s;
}

.clickable-row-history td {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  transition: background-color .2s;
}

.historynavbar {
  transition: transform 0.1s;
}

.historynavbar:hover {
  transform: scale(1.03)
}

.chart-container-history {
  max-width: 96%;
  margin: 0 auto 16px;
}

.input-container-history-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.history-date-range-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-field-history-date-range-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-container-history-title h3 {
  margin-right: 20px;
}

.input-container-history {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.input-container-history p {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.input-field-history, .input-field-history-tenant {
  position: relative;
  user-select: none;
}

.input-field-history-close--date,
.input-field-history-close--number {
  position: absolute;
  height: 36px;
  width: 100px;
}

.input-field-history-tenant::before {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 14px;
  left: 22px;
  content: url('../../images/icons/Layer Group.svg');
  z-index: 2;
}

.input-field-history label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: 0.02em;
  color: #343232;
}

.input-edit-history {
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  padding: 7px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.select-edit-history {
  min-width: 200px;
  min-height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 5px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.input-edit-history::placeholder {
  font-size: 0.9em;
  color: #A4A3A3;
}

.input-edit-history:not(:disabled):hover {
  box-shadow: 0 0 3px 0.5px #e7e6e6;
}

.input-edit-history:not(:disabled):focus {
  box-shadow: 0 0 3px 0.5px #D6D6D6;
}

.input-edit-history:focus {
  box-shadow: 0 0 3px 2px #D6D6D6;
  outline: none;
  border-color: #d3d3d3;
}

.history-main {
  margin: 32px 40px 40px 40px;
}

.history-main-title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.history-nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  gap: 8px;
}

.history-item {
  display: block;
  padding: 0 8px 8px 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  text-decoration: none;

  transition: color 0.3s;
}

.history-item:hover {
  color: #9D1B1F;
}

.history-item--active {
  position: relative;
  color: #9D1B1F;
}

.history-item--active:after {
  position: absolute;
  background-color: #9D1B1F;
  content: "";
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
}

.table-th-history button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.table-th-history button:hover {
  color: #9D1B1F
}

.table-th-history button.ascending::after {
  content: '\2193';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.table-th-history button.descending::after {
  content: '\2191';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.metric-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.sum-metric {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 96%;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
  transition: .3s ease-in-out;
}

.sum-metric-item {
  padding-right: 20px;
}

.sum-metric-label {
  font-size: 12px;
  color: #A4A3A3;
}

.sum-metric-value {
  font-size: 20px;
  color: #343232;
  transition: .3s ease-in-out;
}

.sum-metric-value-download,
.chart-metric-download {
  opacity: .5;
  pointer-events: none;
  transition: .3s ease-in-out;
}

.chart-metric {
  transition: .3s ease-in-out;
}

.csv-download-loading {
  width: 130px;
  height: 32px;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.mini-loader-csv {
  position: relative;
  top: 2px;
  width: 18px;
  height: 18px;
  border: 3px solid #ccc;
  border-bottom-color: #B72025;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.history-chart-loading,
.input-edit-history-loading {
  cursor: wait;
}

.email-type-select {
  position: relative;
}

.email-type-select:before {
  width: 18px;
  height: 20px;
  position: absolute;
  top: 7px;
  left: 11px;
  content: url('../../images/icons/email-mail-sent-icon.svg');
}
