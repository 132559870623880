.us-database-main {
  margin: 32px 40px 40px 40px;
  min-width: 950px;
}

.us-database-main-title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.us-database {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: calc(100vw - 334px);
}

.us-database-sync-option {
  position: relative;
  top: -12px
}

.us-database-sync-option button {
  padding: 12px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #B72025;
  background-color: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1s linear;
}

.us-database-sync-option button:not(:disabled):hover {
  color: #FFFFFF;
  background-color: #B72025;
}

.us-database-sync-option button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.us-database-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  gap: 8px;
}

.us-database-item {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 0 8px 8px 8px;
  min-width: fit-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  text-decoration: none;

  transition: color 0.3s;
}

.us-database-item--active img {
  display: inline-block;
}

.us-database-item--inactive-checked,
.us-database-item--active-checked {
  padding: 0 28px 8px 8px;
}

.us-database-item--active-checked span::after {
  content: url('../../../images/icons/Check Circle Red.svg');
  position: absolute;
  margin-left: 4px;
}

.us-database-item--inactive-checked span::after {
  content: url('../../../images/icons/Check Circle 2.svg');
  position: absolute;
  margin-left: 4px;
}

.us-database-item:not(.us-database-item--inactive):hover {
  color: #9D1B1F;
}

.us-database-item--active {
  position: relative;
  color: #9D1B1F;
}

.us-database-item--active:after {
  position: absolute;
  background-color: #9D1B1F;
  content: "";
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
}

.us-database-item--inactive {
  opacity: 0.5;
  cursor: not-allowed;
}
