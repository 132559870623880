.select-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  min-width: 650px;
  justify-content: space-between;
}

.analytics-report-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 1px solid #E5E5E5;
}

.analytics-report-item-email {
  color: #5D5B5B;
  font-size: 14px;
  font-weight: 400;
  display: block;
  width: max-content;
  padding-bottom: 5px;
}

.analytics-report-item-name {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding-right: 10px;
  display: block;
  width: max-content;
}

.analytics-report-button.modal-button {
  margin-top: 20px;
}

.generate-report-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.analytics-report-item > i {
  display: inline-block;
  position: relative;
  right: 0;
  width: 20px;
  height: 20px;
  opacity: .5;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.analytics-report-item > i:not(.tenants-clear-disabled):hover {
  opacity: .8;
}

.analytics-report-item .tenants-clear-disabled {
  opacity: .2;
  cursor: default;
}

.analytics-report-item > i::before {
  width: 18px;
  position: absolute;
  top: px;
  right: 0;
  content: url('../../../images/icons/Cross.svg');
}

.select-pages-title {
  display: block;
  margin-bottom: 8px;
  color: #343232;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.select-pages-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.select-pages-container input {
  width: 14px;
  height: 14px;
  accent-color: #B72025;
  cursor: pointer;
}

.select-pages-input {
  margin-right: 6px;
  transform: translate(0, 1px);
}

.report-modal-select .react-select__control {
  width: 320px !important;
}
