.prone-tables-grid {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.prone-tables-grid .table-container {
  position: relative;
  height: 100%;
  flex: 1 1 calc(50% - 20px);
  box-sizing: border-box;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px #00000014;
  overflow-y: hidden;
}

.prone-tables-grid .table-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prone-tables-grid .table-title-container button {
  margin: 20px;
  padding: 9px 20px;
  display: flex;
  min-width: 135px;
  border: 1px solid #C42D28;
  border-radius: 100px;
  background-color: #C42D28;
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
  animation: appear .2s ease-in-out;
}

.prone-tables-grid .table-title-container button svg {
  position: relative;
  margin-right: 6px;
}

.prone-tables-grid .table-title-container button:hover {
  background-color: #FFF;
  color: #C42D28;
}

.prone-tables-grid .table-title-container button svg path {
  transition: fill .2s ease-in-out;
}

.prone-tables-grid .table-title-container button:hover svg path {
  fill: #C42D28;
}

.prone-tables-grid .table-title-container button:active {
  transform: translateY(2px);
}

.prone-tables-grid .table-title-container .csv-download-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  background-color: #FFF;
}

.prone-tables-grid .table-title-container .csv-download-loading span {
  position: relative;
  top: -0.5px;
}

.prone-tables-grid .table-container .no-data-available {
  padding-left: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #888888;
}

.prone-tables-container .prone-tables-grid .table-container:last-of-type {
  flex-grow: 0;
  flex-basis: calc(50% - 10px);
}

.prone-tables-grid .table-container h3 {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 20px;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}

.prone-tables-grid table {
  width: 100%;
  border-collapse: collapse;
}

.prone-tables-grid .table-container h3,
.prone-tables-grid .table-container .no-data-available,
.prone-tables-grid table {
  animation: appear 300ms ease-in-out;
}

.prone-tables-grid thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.prone-tables-grid thead::before,
.prone-tables-grid thead::after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #00000026;
}

.prone-tables-grid thead::after {
  top: 0;
}

.prone-tables-grid th {
  padding: 12px 24px;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  background: #FCFCFD;
}

.prone-tables-grid td {
  width: 100%;
  min-height: 51px;
  padding: 16px 0 16px 24px;
  text-align: left;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid #00000026;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.prone-tables-grid td span:nth-of-type(2) {
  font-size: 10px;
}

.prone-tables-grid th {
  font-weight: bold;
}

.prone-tables-grid tbody tr:last-child td {
  border-bottom: none;
}

.prone-tables-grid .download-certificate {
  position: absolute;
  padding: 0;
  margin: 0;
  height: 25px;
  border: none;
  background: none;
  transform: translate(1px, -4px);
  cursor: pointer;
  transition: transform .2s ease-in-out, opacity .2s ease-in-out;
}

.prone-tables-grid .download-certificate:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.prone-tables-grid .download-certificate:active {
  transform:
    translate(1, -4px)
    scale(0.8);
}

.prone-tables-grid .download-certificate svg {
  width: 22px;
}

.prone-tables-grid .download-certificate svg path {
  transition: fill .2s ease-in-out;
}

.prone-tables-grid .download-certificate svg:hover path:nth-of-type(1) {
  fill: #5D5B5B;

}

.prone-tables-grid .download-certificate svg:hover path:nth-of-type(2) {
  fill: #B72025;
}
