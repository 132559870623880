.add-columns-button-container {
  position: absolute;
  z-index: 100;
}

.add-columns-button {
  margin: 0;
  padding: 0;
  height: max-content;
  background-color: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.8;
}

.add-columns-button:hover {
  opacity: 1;
}

.add-columns-button:active {
  transform: scale(0.9);
}

.column-selector-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  width: 190px;
  opacity: 0;
  border-radius: 4px;
  transition: opacity 0.3s;
}

.column-selector-modal--open {
  opacity: 1;
  animation: fadeInScale 100ms ease-out forwards;
}

.column-selector-label {
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  transition: color 200ms ease-in-out;
}

.column-selector-label:hover {
  color: #EFB7B6;
}

.column-selector-label input {
  accent-color: #B72025;
}

.column-selector-label:has(input:checked) {
  color: #FBEDED;
}
