.reports-main {
  margin: 32px 40px 40px 40px;
}

.reports-main-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.reports-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 1100px;
}

.reports-main-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #343232;
}

.reports-main .user-configuration-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 2px solid transparent;
  padding: 7px 16px;
  background-color: #fff;
  border-radius: 4px;
  color: #B72025;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
  border: 1px solid #B72025;
  cursor: pointer;
  transition: transform .1s ease-in-out,
    background-color .2s ease-in-out,
    color .2s ease-in-out;
}

.reports-main .user-configuration-settings:hover {
  background-color: #B72025;
  color: #fff;
}

.reports-main .user-configuration-settings:active {
  transform: scale(0.99);
}

.reports-main .user-configuration-settings path {
  cursor: pointer;
  transition: fill .2s ease-in-out;
}

.reports-main .user-configuration-settings:hover path {
  fill: #fff;
}

.phish-reports-select {
  position: relative;
  z-index: 3;
  width: 500px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px #3432321f;
  border-radius: 4px;
  margin-bottom: 10px;
}

.phish-reports-select::before {
  width: 14px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 13px;
  content: url('../../images/icons/Search.svg');
}

.phish-reports-select--disabled {
  opacity: 0.5;
  cursor: not-allowed;
  user-select: none;
}

.reports-nav {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.reports-item {
  display: block;
  padding: 0 8px 8px 8px;
  min-width: fit-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  text-decoration: none;

  transition: color 0.3s;
}

.reports-item:not(.reports-item--inactive):hover {
  color: #9D1B1F;
}

.reports-item--active {
  position: relative;
  color: #9D1B1F;
}

.reports-item--active:after {
  position: absolute;
  background-color: #9D1B1F;
  content: "";
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 2px;
}

.reports-item--inactive {
  opacity: 0.5;
  cursor: not-allowed;
}

.reports-card {
  width: 100%;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.reports-card-title-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reports-card-title {
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 0.02em;
  color: #343232;
}

.reports-table {
  margin: 16px 0 0 0;
  display: grid;
  border-collapse: collapse;
  min-width: 1040px;
  width: 100%;
  grid-template-columns:
    minmax(28px, 0.5fr)
    minmax(140px, 1fr)
    minmax(140px, 2fr)
    minmax(300px, 5fr)
    minmax(150px, 2fr)
    minmax(140px, 140px);
  border: 1px solid #D6D6D6;
  border-radius: 4px;
}

.reports-table-detailed {
  grid-template-columns:
    minmax(28px, 0.5fr)
    minmax(120px, 1fr)
    minmax(140px, 2fr)
    minmax(200px, 3fr)
    minmax(300px, 6fr)
    minmax(150px, 2fr)
    minmax(130px, 130px)
    minmax(40px, 0.5fr)
}

.reports-table--paginated {
  margin-bottom: 16px;
}

.table-th-reports {
  display: contents;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
}

.tr-grid-reports th,
.tr-grid-reports td {
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.tr-grid-reports td {
  display: flex;
  align-items: center;
  padding: 7px 0 7px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
  border-bottom: 1px solid #E6E6E6;
}

.tr-grid-reports:last-of-type td {
  border-bottom: none;
}

.reports-table-detailed .tr-grid-reports td:last-of-type {
  padding-left: 0;
  border-left: 1px solid #E6E6E6;
}

.tr-grid-reports td:nth-last-of-type(2),
.tr-grid-reports th:nth-last-of-type(2),
.tr-grid-reports td:last-of-type,
.tr-grid-reports th:last-of-type {
  text-align: center;
  justify-content: center;
}

.tr-grid-reports th {
  padding: 11px 0 11px 12px;
  text-align: left;
  background: #F3F3F3;
  border-bottom: 1px solid #E6E6E6;
  z-index: 2;
}

.table-th-reports,
.table-body-reports,
.tr-grid-reports {
  display: contents;
}

.tr-reports {
  background: #F3F3F3;
  width: fit-content;
}

.reports-button {
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
}

.reports-button--close::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url('../../images/icons/Chevron-down-small.svg');
  background-size: contain;
  position: relative;
  margin-left: 5px;
  top: 2px;
}

.reports-button--open::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url('../../images/icons/Chevron-down-small-white.svg');
  background-size: contain;
  transform: rotate(180deg);
  position: relative;
  margin-left: 5px;
  top: 2px;
}

.reports-button--open {
  color: #FFFFFF;
  border-bottom: none !important;
}

.reports--open {
  position: sticky;
  top: 63px;
  background: #fff;
  border-bottom: 1px solid #B72025 !important;
  border-color: #B72025;
  z-index: 1;
}

.reports--open:not(:last-of-type) {
  cursor: url('../../images/icons/noun-close-tab.svg'), auto;
}

.reports--open:nth-last-of-type(2) {
  cursor: pointer;
}

.reports-table-detailed .reports--open:nth-last-of-type(2),
.reports--open:not(.reports--open-last):last-of-type {
  background: #9D1B1F;
  border-radius: 4px 4px 0px 0px;
  border-bottom: none !important;
}

.report-details {
  display: block;
  width: 100%;
}

.reports-additional {
  grid-column: 1 / -1;
  border: 1px solid #B72025;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
  padding: 20px;
}

.reports-additional-info {
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  margin-bottom: 20px;
}

.reports-additional-info::before {
  content: url('../../images/icons/Info Circle Black.svg');
  position: relative;
  margin-right: 4px;
  top: 3px;
}

.reports-alert {
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #9D1B1F;
  margin-bottom: 20px;
  text-align: center;
}

.reports-alert::before {
  content: url('../../images/icons/Alert\ triangle\ 2.svg');
  position: relative;
  margin-right: 4px;
  top: 3px;
}

.reports-additional-detailed {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 30px;
}

.reports-additional-detailed > ul {
  min-width: 480px;
  width: fit-content;
  overflow-x: auto;
}

.reports-additional-detailed > ul > li {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.reports-additional-detailed > ul > li:not(:last-of-type) {
  margin-bottom: 16px;
}

.reports-additional-detailed > ul > li > pre {
  display: block;
  margin-top: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
}

pre {
  font-family: 'Inter', sans-serif;
}

.reports-additional-actions--visible {
  visibility: visible;
  transition: .1s ease-in-out;
}

.reports-additional-actions--hide {
  visibility: hidden;
}

.reports-additional-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}


.reports-additional-actions--2,
.reports-additional-actions--3 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.reports-additional-actions--2 > span,
.reports-additional-actions--3 > span,
.reports-additional-submit--comment {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
}

.reports-additional-submit--comment,
.reports-additional-submit--comment span {
  display: flex;
  justify-content: flex-end;
}

.reports-additional-submit--comment {
  flex-direction: column;
}

.reports-additional-submit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}

.reports-additional-actions--1 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.reports-additional-actions--1 > button {
  position: relative;
  padding: 7px 12px 7px 30px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  background-color: #FFFFFF;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color .1s ease-in-out;
}

.reports-additional-actions--1 > button:first-of-type:before {
  content: url('../../images/icons/Preview.svg');
  position: absolute;
  left: 13px;
  top: 9px;
}

.reports-additional-actions--1 > button:last-of-type:before {
  content: url('../../images/icons/preview-json.svg');
  position: absolute;
  left: 10px;
  top: 8px;
}

.reports-additional-actions--1 > button:not(:disabled):hover {
  background-color: #5D5B5B;
  border-color: #5D5B5B;
  color: #FFFFFF;
  cursor: pointer;
}

.reports-additional-actions--1 > button:first-of-type:not(:disabled):hover::before {
  content: url('../../images/icons/Preview-white.svg');
}

.reports-additional-actions--1 > button:last-of-type:not(:disabled):hover::before {
  content: url('../../images/icons/preview-json-white.svg');
}

.reports-additional-actions--1 > button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.reports-additional-actions-button {
  padding: 7px 16px;
  background-color: #F3F3F3;
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
  transition: border-color .2s ease-in-out;
}

.reports-additional-actions-button:not(:disabled):hover {
  border-color: #B72025;
}

.reports-additional-actions-button:disabled {
  cursor: not-allowed;
}

.reports-additional-actions-button--active {
  background-color: #B72025;
  color: #fff;
}

.reports-additional-actions--comment {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.reports-additional-submit--cancel {
  padding: 7px 16px;
  background-color: #FFFFFF;
  border: 1px solid #C2C1C1;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.reports-additional-submit--cancel:hover {
  border-color: #5D5B5B;
  background-color: #5D5B5B;
  color: #FFFFFF;
}

.reports-additional-submit--confirm {
  position: relative;
  padding: 7px 12px 7px 30px;
  background-color: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #B72025;
  cursor: pointer;
  transition: background-color .1s ease-in-out;
}

.reports-additional-submit--confirm::before {
  content: url('../../images/icons/Check-red-small.svg');
  position: absolute;
  left: 13px;
  top: 9px;
}

.reports-additional-submit--confirm:not(:disabled):hover {
  background-color: #B72025;
  color: #FFFFFF;
  cursor: pointer;
}

.reports-additional-submit--confirm:not(:disabled):hover::before {
  content: url('../../images/icons/Check-white-small.svg');
}

.reports-additional-submit--confirm:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.reports-modal {
  padding: 70px 32px 32px 32px;
  width: 80vw;
  height: 80vh;
  z-index: 11;
}

.reports-modal-cancel-title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  text-align: left;
}

.reports-modal-cancel-subscription {
  margin-bottom: 24px;
}

.reports-modal-cancel-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.reports-modal-gotit-button {
  display: flex;
  justify-content: center;
}

.reports-modal-gotit-button button {
  margin-bottom: 32px;
  padding: 7px 12px;
  background-color: #B72025;
  border: 1px solid #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
  transition: all .1s ease-out;
}

.reports-modal-gotit-button button:hover {
  background-color: #FFFFFF;
  color: #B72025;
}

.reports-modal-cancel-button button:first-of-type {
  padding: 7px 12px;
  background-color: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #B72025;
  cursor: pointer;
  transition: all .1s ease-out;
}

.reports-modal-cancel-button button:first-of-type:hover {
  background-color: #B72025;
  color: #FFFFFF;
}

.reports-modal-cancel-button button:last-of-type {
  padding: 7px 12px;
  background-color: #B72025;
  border: 1px solid #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
  transition: all .1s ease-out;
}

.reports-modal-cancel-button button:last-of-type:hover {
  background-color: #FFFFFF;
  color: #B72025;
}

.reports-modal-close {
  border: none;
  background: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: all .1s ease-out;
  opacity: 0.6;
}

.reports-modal-close:hover {
  opacity: 1;
}

.reports-modal-close:active {
  transform: scale(0.9);
}

.reports-modal-close::after {
  content: url('../../images/icons/Cross\ Grey.svg');
}

::-webkit-scrollbar {
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  -webkit-box-shadow: inset 0 0 0.5px #b4b4b4;
}

.button-loader {
  position: relative;
}

.loader-small {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.loader-small:before , .loader-small:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width:24px;
  height: 24px;
  border-radius: 50%;
  background:#B72025;
  transform: translate(-50% , 100%) scale(0);
  animation: push 2s infinite ease-in;
}
.loader-small:after {
animation-delay: 1s;
}

.reports-checkbox-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.reports-checkbox-action span:nth-of-type(2) {
  position: absolute;
  left: 24px;
}

.reports-checkbox-action input {
  display: none;
}

.reports-checkbox-action label {
  display: flex;
  flex-direction: row;
}

.reports-checkbox-action input:checked~.checkbox-span {
  rotate: 45deg;
  translate: 4px -2px;
  width: 8px;
  border-color: #B72025;
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 1px
}

.reports-unselect-button {
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
}

.reports-unselect-button::before {
  content: url('../../images/icons/Cross Red.svg');
  display: block;
  position: absolute;
  top: -15px;
  left: -15px;
  cursor: pointer;
  transition: .2s ease-in-out;
}

.reports-unselect-button:hover::before {
  transform: scale(1.1);
}

.additional-download-reports {
  margin-top: 22px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mini-loader-reports {
  position: relative;
  width: 18px;
  height: 18px;
  border: 3px solid #ccc;
  border-bottom-color: #B72025;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.phish-reports-label::before {
  width: 10px;
  height: 10px;
  position: relative;
  top: 1px;
  margin-right: 5px;
  content: url('../../images/icons/Email template Small.svg');
}

.reports-modal-container {
  position: relative;
  padding: 60px 40px 30px;
}

.reports-modal-container .modal-title {
  margin-bottom: 30px;
}

.reports-modal-container .button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 40px;
  display: flex;
  height: 32px;
  padding: 7px 12px;
  border: 1px solid #B72025;
  background-color: #fff;
  border-radius: 4px;
  color: #B72025;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
  cursor: pointer;

  transition: background-color .2s ease-in-out, color .2s ease-in-out;
}

.reports-modal-container .button path {
  transition: fill .2s ease-in-out;
}

.reports-modal-container .button:not(:disabled):hover {
  background-color: #B72025;
  color: #fff;
}

.reports-modal-container .button:not(:disabled):hover path {
  fill: #fff;
}

.reports-modal-container .button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.reports-modal-container .button:not(:disabled):active {
  transform: scale(0.99);
}

.reports-label {
  color: #343232;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
}
