@keyframes push {
  0% {
    transform: translate(-50% , 100%) scale(1);
  }
  15% , 25%{
    transform: translate(-50% , 50%) scale(1);
  }
50% , 75% {
    transform: translate(-50%, -30%) scale(0.5);
  }
80%,  100% {
    transform: translate(-50%, -50%) scale(0);
  }
}

@keyframes push-opacity-top {
  0% {
    opacity: 0;
    top: -100%;
  }
  100% {
    opacity: 1;
  }
}

@keyframes push-opacity-bottom {
  0% {
    opacity: 0;
    bottom: -100%;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes gradient {
  0% {
    background: radial-gradient(circle at center,
    #ff7d7d00 0%, #fff 0%, #fff 100%);
  }
  25% {
    background: radial-gradient(circle at center,
    #580e0e 24%, #fff 25%, #fff 100%);
  }
  50% {
    background: radial-gradient(circle at center,
    #881d1d 49%, #fff 50%, #fff 100%);
  }
  75% {
    background: radial-gradient(circle at center,
    #b72929 74%, #fff 75%, #fff 100%);
  }
  100% {
    color: #4cb4ad;
    background: radial-gradient(circle at center,
    #ce2e2e 99%, #fff 100%, #fff 100%);
  }
}

@keyframes transform {
  100% {
    transform: translateY(3px);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animloader {
  0% {
    box-shadow: 7px 0 0 -1px,  19px 0 0 -1px, -7px 0 0 -1px, -19px 0 0 -1px;
  }
  25% {
    box-shadow: 7px 0 0 -1px, 19px 0 0 -1px, -7px 0 0 -1px, -19px 0 0 1px;
  }
  50% {
    box-shadow: 7px 0 0 -1px, 19px 0 0 -1px, -7px 0 0 1px, -19px 0 0 -1px;
  }
  75% {
    box-shadow: 7px 0 0 1px, 19px 0 0 -1px, -7px 0 0 -1px, -19px 0 0 -1px;
  }
  100% {
    box-shadow: 7px 0 0 -1px, 19px 0 0 1px, -7px 0 0 -1px, -19px 0 0 -1px;
  }
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes height {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
