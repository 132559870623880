.all-users-main {
  min-width: 710px;
  width: 100%;
  padding: 8px 24px 24px 24px;
  margin-bottom: 32px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.lists-main {
  min-width: 510px;
  width: 100%;
  padding: 24px;
  margin-bottom: 32px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.lists-description {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.list-users-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 16px;
}

.list-users-add-actions {
  margin-bottom: 32px;
}

.email-container {
  height: 24px;
  border: 1px solid #A4A3A3;
  padding: 4px;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.email-container-img {
  width: 15px;
  height: 18px;
  margin-right: 6px;
  margin-left: 6px;
  /* background-color: #343232; */
}

.list-button {
  padding: 12px 25px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #B72025;
  border-radius: 4px;
  border: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
}

.list-button-simple {
  margin-bottom: 0;
}

.list-button:hover {
  background-color: #9D1B1F;
}

.list-button:active {
  background-color: #B72025;
}

.list-button:disabled {
  background-color: #EFB7B6;
  cursor: not-allowed;
}

.list-users-add-title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.list-users-add-subtitle {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.list-select {
  position: relative;
  z-index: 2;
}

.list-select::before {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 10px;
  left: 13px;
  z-index: 2;
  content: url('../../images/icons/Groups.svg');
}

.list-multiselect {
  margin-bottom: 12px;
  min-width: 500px;
  min-height: 40px;
}

.list-audit-desc {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.list-users-table {
  margin-bottom: 25px;
  width: 100%;
  min-width: 1120px;
}

.list-users-clickable-row {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  transition: background-color .2s;
  cursor: pointer;
}

.list-users-not-clickable {
  background-color: #F3F3F3;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
  transition: border-color .2s;
}

.list-users-clickable-row:hover {
  background-color: #FBEDED;
}

.list-selected,
.list-selected:hover {
  background-color: #ffdede;
}

.users-list-tenant-label::before {
  position: relative;
  top: 5px;
  left: 0;
  margin-right: 5px;
  content: url('../../images/icons/noun-tenant.svg');
}

.syncTypes-label-all::before {
  width: 10px;
  height: 10px;
  position: relative;
  top: 3px;
  margin-right: 3px;
  content: url('../../images/icons/all.svg');
}
.syncTypes-label-azure::before {
  width: 10px;
  height: 10px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  content: url('../../images/icons/Azure.svg');
}

.syncTypes-label-google::before {
  width: 10px;
  height: 10px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  content: url('../../images/icons/Google\ \(colors\).svg');
}

.risk-level-label-low-risk::before {
  position: relative;
  top: 4px;
  margin-right: 5px;
  content: url('../../images/icons/levels-low.svg');
}

.risk-level-label-moderate-risk::before {
  position: relative;
  top: 4px;
  margin-right: 5px;
  content: url('../../images/icons/levels-moderate.svg');
}

.risk-level-label-high-risk::before {
  position: relative;
  top: 4px;
  margin-right: 5px;
  content: url('../../images/icons/levels-high.svg');
}

.syncTypes-label-csv::before {
  width: 10px;
  height: 10px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  content: url('../../images/icons/File\ Red.svg');
}

.list-users-tr {
  display: grid;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  padding: 4px 0 4px 12px;
  min-height: 40px;
  text-align: left;
}

.list-users-tr th {
  color: #343232;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
}

.list-users-tr th:last-of-type,
.list-users-tr td:last-of-type {
  min-width: 30px;
}

.list-users-tr th,
.list-users-tr td {
  opacity: 0;
  transform: scale(0.9);
  animation: fadeInScale 200ms ease-out forwards;
}

.list-users-tr td {
  color: #343232;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.list-users-tr .list-td-risk-level {
  font-weight: 500;
}

.list-users-tr .list-td-risk-level--low {
  color: #28b865;
}

.list-users-tr .list-td-risk-level--moderate {
  color: #ed8f00;
}

.list-users-tr .list-td-risk-level--high {
  color: #de2c37;
}

.list-users-tr td i {
  cursor: pointer;
}

.list-td-edit button {
  display: none;
}

.list-th-edit::after {
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  right: 3px;
  content: url('../../images/icons/Edit.svg');
}

.list-td-edit i::after {
  display: block;
  height: 20px;
  width: 20px;
  content: url('../../images/icons/User\ Edit\ Grey.svg');
  transition: all .1s ease-in-out;
}

.list-td-edit i:hover:after {
  display: block;
  height: 20px;
  width: 20px;
  content: url('../../images/icons/User\ Edit\ Red.svg');
  transform: scale(1.1);
}

.select-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 54px;
}

.button-delete {
  border: none;
  background: none;
  padding: 0;
  transition: all .2s;
}

.button-delete:disabled {
  opacity: 0.4;
}

.button-delete:not(:disabled) {
  opacity: 0.8;
  cursor: pointer;
}

.button-delete:not(:disabled):hover {
  opacity: 1;
  transform: scale(1.1);
}

.button-delete img {
  width: 24px;
  height: 24px;
}

.list-users-label::before {
  width: 11px;
  height: 8px;
  position: relative;
  top: 3px;
  margin-right: 5px;
  content: "";
}

.list-caption {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #A4A3A3;
}

.list-users-not-clickable button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.list-users-not-clickable button:hover {
  color: #9D1B1F
}

.list-users-not-clickable button.ascending::after {
  content: '\2193';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.list-users-not-clickable button.descending::after {
  content: '\2191';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.list-selected-bar {
  margin: 8px 8px 8px 0;
}

.userslist-select {
  position: relative;
  z-index: 4;
}

.userslist-select-tenant::before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 31px;
  left: 12px;
  content: url('../../images/icons/Layer Group.svg');
}

.userslist-field-tenant-csv:before {
  content: url('../../images/icons/File\ Red.svg');
}
.userslist-field-tenant-google:before {
  content: url('../../images/icons/Google\ \(colors\).svg');
}
.userslist-field-tenant-azure:before {
  content: url('../../images/icons/Azure.svg');
}

.userslist-select-language::before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 34px;
  left: 12px;
  content: url('../../images/icons/world-svgrepo-com.svg');
}
.userslist-select-groups::before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 34px;
  left: 12px;
  content: url('../../images/icons/Groups.svg');
}

.userslist-select-actions,
.userslist-select-sync {
  position: relative;
  z-index: 5;
}

.userslist-select-actions::before {
  width: 23px;
  height: 23px;
  position: absolute;
  top: 31px;
  left: 9px;
  content: url('../../images/icons/actions-run-build.svg');
}

.userslist-select-actions-lang::before {
  width: 23px;
  height: 23px;
  position: absolute;
  top: 34px;
  left: 11px;
  content: url('../../images/icons/world-svgrepo-com.svg');
}

.userslist-select-actions-delete::before {
  width: 15px;
  height: 14px;
  position: absolute;
  top: 22px;
  left: -1px;
  content: url('../../images/icons/user-disabled.svg');
}

.userslist-select2-all::before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 33px;
  left: 13px;
  content: url('../../images/icons/all.svg');
}

.userslist-select-sync::before {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 33px;
  left: 13px;
  content: url('../../images/icons/Sync.svg');
}

.userslist-select-risk-level::before {
  position: absolute;
  top: 34px;
  left: 13px;
  content: url('../../images/icons/levels.svg');
}

.userslist-select-risk-level-low-risk::before {
  content: url('../../images/icons/levels-low.svg');
}

.userslist-select-risk-level-low-risk::before {
  content: url('../../images/icons/levels-low.svg');
}

.userslist-select-risk-level-moderate-risk::before {
  content: url('../../images/icons/levels-moderate.svg');
}

.userslist-select-risk-level-high-risk::before {
  content: url('../../images/icons/levels-high.svg');
}

.userslist-select-non-engagement::before {
  position: absolute;
  top: 34px;
  left: 12px;
  content: url('../../images/icons/uneducated.svg');
}

.userslist-select-non-engagement--active::before {
  position: absolute;
  top: 34px;
  left: 12px;
  content: url('../../images/icons/uneducated-color.svg');
}

.userslist-select2-google::before {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 32px;
  left: 13px;
  content: url('../../images/icons/Google\ \(colors\).svg');
}

.userslist-select2-csv::before {
  z-index: 2;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 32px;
  left: 13px;
  content: url('../../images/icons/File\ Red.svg');
}

.userslist-select2-azure::before {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 32px;
  left: 13px;
  content: url('../../images/icons/Azure.svg');
}

.list-td-email-icon {
  margin: 0 8px;
  display: inline-block;
  transform: translateY(3px);
  width: 14px;
  height: 14px;
}

.userslist-select-label {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.userslang-select::before {
  width: 14px;
  height: 12px;
  position: absolute;
  top: 7px;
  left: 18px;
  content: url('../../images/icons/world-svgrepo-com.svg');
}

.actions-label-lang,
.actions-label-delete {
  position: relative;
  padding-left: 24px;
}

.actions-label-lang::before {
  width: 14px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  content: url('../../images/icons/world-svgrepo-com-blue.svg');
}

.actions-label-delete::before {
  width: 15px;
  height: 14px;
  position: absolute;
  top: -11px;
  left: -14px;
  content: url('../../images/icons/user-disabled.svg');
}

.multiselect-users {
  width: 100%;
}

.all-users-filters {
  display: block;
  position: -webkit-sticky;
  position: sticky;
  background-color: #FFFFFF;
  z-index: 5;
  top: 128px;
}

.all-users-filters-input {
  max-width: calc(100vw - 380px);
}

.all-users-filters--hide {
  display: none;
}

.all-users-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.form-delete,
.form-lang {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;

  background: #FFFFFF;
  border-radius: 4px;
}

.form-lang .card-subtitle {
  display: block;
  margin-bottom: 8px;
}

.content-delete {
  margin-bottom: 20px;
}

.form-delete .card-title {
  display: block;
  margin-bottom: 0;
  color: #B72025;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.form-delete .card-subtitle,
.no-users-card-subtitle {
  display: block;
  margin-bottom: 10px;
  text-align: center;
  color: #343232;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.no-users-card-subtitle {
  margin-bottom: 30px;
}

.delete-scroll {
  max-height: 320px;
  overflow-y: scroll;
}

.all-users-select,
.all-users-select--with-label {
  width: fit-content;
  position: relative;
  z-index: 2;
}

.all-users-select::before,
.all-users-select--with-label::before {
  width: 14px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 13px;
  content: url('../../images/icons/Search.svg');
}

.all-users-select--with-label::before {
  top: 32px;
}

.all-users-selected-bar {
  display: flex;
  margin: 8px 8px 8px 0;
  align-items: center;
}

.all-users-email-button-active::after {
  width: 8px;
  max-height: 8px;
  position: relative;
  margin-left: 4px;
  top: 3px;
  content: url('./../../images/icons/expand-collapse.svg');
}

.all-users-email-button {
  display: flex;
  align-items: center;
  border: 1px solid #A4A3A3;
  height: 24px;
  border-radius: 4px;
  background-color: #F3F3F3;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  margin-right: 8px;
  cursor: pointer;
  transition: border-color .1s ease-in-out;
}

.all-users-email-button:hover {
  border-color: #5D5B5B;
}

.all-users-email-button::after {
  width: 8px;
  height: 8px;
  position: relative;
  margin: 0 6px 0 4px;
  top: -4px;
  content: url('./../../images/icons/sort.svg');
}

.all-users-email-button-active::after {
  width: 8px;
  max-height: 8px;
  position: relative;
  margin: 0 6px 0 4px;
  top: -4px;
  content: url('./../../images/icons/expand-collapse.svg');
}

.all-users-email-button-active {
  display: flex;
  align-items: center;
  border: 1px solid #A4A3A3;
  height: 24px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
  margin-right: 8px;
  background-color: #5D5B5B;
  height: fit-content;
}

.all-users-secondary-button {
  height: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  background: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #B72025;
  height: fit-content;
}

.all-users-secondary-button:not(:disabled):hover {
  background-color: #B72025;
  color: #FFFFFF;
}

.all-users-secondary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.all-users-emails-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;
}

.all-users-email-container {
  height: 24px;
  border: 1px solid #A4A3A3;
  padding: 4px;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  display: flex;
  align-items: center;
  gap: 6px;
}

.all-users-email-container-img {
  height: 18px;
  margin: 10px 4px 0 4px;
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.all-users-email-container-img:hover {
  transform: scale(1.1);
}

.all-users-search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.all-users-search-container-empty {
  margin-bottom: 8px;
}


.all-users-card-action-modal-remove {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.all-users-card-action-modal-remove button {
  width: 200px;
  height: 48px;
  border: 2px solid transparent;
  background-color: #B72025;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;

  transition: background-color .3s;
}

.all-users-card-action-modal-remove button:last-of-type {
  background-color: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  color: #B72025;
}

.all-users-card-action-modal-remove button:not(:disabled):hover {
  background-color: #9D1B1F !important;
}

.all-users-card-action-modal-remove button:last-of-type:hover {
  background-color: #9D1B1F;
  color: #FFFFFF;
}

.all-users-card-action-modal-remove button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.all-users-card-action-modal-remove button:not(:disabled):active {
  transform: translateY(2px);
}

.no-users-button-secondary {
  width: 100%;
}

.all-users-table-clickable-row {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  transition: background-color .2s;
}

.all-users-table-clickable-row:not(.group-selected):hover {
  background-color: #FBEDED;
}

.input-field .react-select__control {
  height: 48px;
}
.input-field .react-select__value-container {
  font-size: 16px;
}

.input-field--language::before {
  position: absolute;
  content: url('../../images/icons/world-svgrepo-com.svg');
  top: 15px;
  left: 12px;
}

.users-main-filter-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  padding: 16px;
  background-color: #FFF;
  z-index: 1;
}

.list-audit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 1120px;
  max-width: calc(100vw - 412px);
}

.all-users-filters-button {
  position: relative;
  padding: 5px 15px;
  height: 32px;
  border: 1px solid #A4A3A3;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
  transition: border-color 100ms ease-in-out,
    color 100ms ease-in-out,
    transform 50ms ease-in-out;
}

.all-users-filters-button:hover {
  border-color: #9D1B1F;
  color: #9D1B1F;
}

.all-users-filters-button--active:active {
  transform: translateY(-2px);
}

.all-users-filters-button--inactive:active {
  transform: translateY(2px);
}

.list-users-table-container .add-columns-button-container:has(.add-columns-button--left) {
  left: -24px;
  top: -19px;
}

.list-users-table-container .add-columns-button-container:has(.add-columns-button--right) {
  right: 14px;
  top: -14px;
}

.list-users-table-container .add-columns-button-container:has(.add-columns-button--left) .column-selector-modal {
  top: 47px;
  left: 2px;
}
.list-users-table-container .add-columns-button-container:has(.add-columns-button--right) .column-selector-modal {
  top: 41px;
  right: 2px;
}

.list-users-table-container .add-columns-button--left {
  transform: rotate(180deg);
}

.list-users-table-container .add-columns-button--left:active {
  transform: rotate(180deg) scale(0.9);
}

.list-users-table-container .list-users-table-th {
  position: -webkit-sticky;
  position: sticky;
  top: 280px;
  background-color: #fff;
  overflow: hidden;
  z-index: 1;
}
.list-users-table-container .list-users-table-th--top {
  top: 128px;
}
