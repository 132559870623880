.analytics-metrics-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.8fr;
}

.analytics-metrics-item-general {
  padding: 15px;
  border-radius: 5px;
  background: #FFF;
}

.analytics-metrics-item .analytics-metrics-item-container:not(:last-of-type) {
  margin-bottom: 20px;
}

.analytics-metrics-item .analytics-metrics-item-container {
  border-left: 1px solid #00000026;
  padding: 0 5px 0 20px;
}

.analytics-metrics-item .analytics-metrics-value {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  height: 20px;
}

.analytics-metrics-item .analytics-metrics-value-current {
  margin-right: 5px;
  color: #000;
  animation: appear 300ms ease-in-out;
}

.analytics-metrics-item .analytics-metrics-value-prev {
  font-size: 14px;
  opacity: 0.7;
  animation: appear 300ms ease-in-out;
}

.analytics-metrics-item .analytics-metrics-value-prev--positive {
  color: #28b865;
  opacity: 1;
}
.analytics-metrics-item .analytics-metrics-value-prev--negative {
  color: #de2c37;
  opacity: 1;
}

.analytics-metrics-item .analytics-metrics-label {
  display: flex;
  gap: 4px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}

.analytics-metrics-item .analytics-metrics-item-general .analytics-metrics-label {
  margin-bottom: 10px;
}

.metrics-loader-container {
  margin-bottom: 5px;
  height: 20px;
  width: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.7;
  animation: appear 300ms ease-in-out;
}

.metrics-loader-container-table {
  justify-content: center;
}

.metrics-loader-container-graph-value {
  height: 29px;
  margin-bottom: 15px;
}

.metrics-loader-container-graph-dates {
  height: 18px;
  margin: 0 25px 0 0;
}

.metrics-loader-container-graph-value,
.metrics-loader-container-graph-dates {
  opacity: 1;
}

.metrics-loader {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  text-align: start;
  margin-left: 20px;
  position: relative;
  color: #000;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}
