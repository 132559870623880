.groups-main {
  min-width: 510px;
  width: 100%;
  padding: 24px;
  margin-bottom: 32px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(52, 50, 50, 0.12);
  border-radius: 4px;
}

.user-checkbox-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
  top: 12px
}

.user-checkbox-action span:nth-of-type(2) {
  position: absolute;
  left: 24px;
}

.user-checkbox-action input {
  display: none;
}

.user-checkbox-action label {
  display: flex;
  flex-direction: row;
}

.user-checkbox-action input:checked~.checkbox-span {
  rotate: 45deg;
  translate: 4px -2px;
  width: 8px;
  border-color: #B72025;
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 1px
}

.groups-description {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
}

.new-group-users-table-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 16px;
}

.new-group-users-table-add-actions {
  margin-bottom: 32px;
}

new-users-group-input {
  margin-bottom: 16px;
}

.secondary-button {
  height: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  background: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #B72025;
}

.secondary-button:not(:disabled):hover {
  background-color: #B72025;
  color: #FFFFFF;
}

.secondary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.emails-container {
  display: flex;
  flex-wrap: wrap;
}

.email-container {
  height: 24px;
  border: 1px solid #A4A3A3;
  padding: 4px;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.email-container-img {
  width: 15px;
  height: 18px;
  margin-right: 6px;
  margin-left: 6px;
margin-top: 12px;
  /* background-color: #343232; */
}


.email-button {
  border: 1px solid #A4A3A3;
  height: 24px;
  border-radius: 4px;
  background-color: #F3F3F3;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5B5B;
  cursor: pointer;
  margin-right: 8px;
}

.email-button:hover {
  border: 2px solid #A4A3A3;
}

.email-button::after {
  width: 8px;
  height: 8px;
  position: relative;
  margin-left: 4px;
  top: 4px;
  content: url('./../../images/icons/sort.svg');
}

.email-button-active::after {
  width: 8px;
  max-height: 8px;
  position: relative;
  margin-left: 4px;
  top: 3px;
  content: url('./../../images/icons/expand-collapse.svg');
}

.email-button-active {
  border: 1px solid #A4A3A3;
  height: 24px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  cursor: pointer;
  margin-right: 8px;
  background-color: #5D5B5B;
}

.group-button {
  padding: 12px 25px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #B72025;
  border-radius: 4px;
  border: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
}

.group-button-secondary {
  padding: 12px 25px;
  height: 40px;
  min-width: 120px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  background: #FFFFFF;
  border: 1px solid #B72025;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #B72025;
}

.group-button-secondary:hover {
  background-color: #9D1B1F;
  color: #FFFFFF;
}

.group-button-secondary:active {
  background-color: #B72025;
  color: #FFFFFF;
}

.group-button-secondary:disabled {
  background-color: #EFB7B6;
  color: #FFFFFF;
  border: none;
  cursor: not-allowed;
}
.group-button-simple {
  margin-bottom: 8px;
}

.group-button:not(.group-button-simple):before {
  width: 10px;
  height: 10px;
  position: relative;
  margin-right: 7px;
  content: url('./../../images/icons/Plus.svg');
}

.group-button:hover {
  background-color: #9D1B1F;
}

.group-button:active {
  background-color: #B72025;
}

.group-button:disabled {
  background-color: #EFB7B6;
  margin-bottom: 8px;
}

.new-group-users-table-add-title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.new-group-users-table-add-subtitle {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.group-select {
  position: relative;
  z-index: 2;
}

.group-select::before {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 10px;
  left: 13px;
  z-index: 2;
  content: url('../../images/icons/Groups.svg');
}

.group-multiselect {
  margin-bottom: 12px;
  min-height: 40px;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.group-audit-desc {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.new-group-users-table-table {
  margin-bottom: 25px;
  width: 100%;
}

.new-group-users-table-clickable-row {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  transition: background-color .2s;
  cursor: pointer;
}

.new-group-users-table-not-clickable {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #E6E6E6;
  text-align: left;
  transition: border-color .2s;
}

.new-group-users-table-clickable-row:hover {
  background-color: #FBEDED;
}

.group-selected,
.group-selected:hover {
  background-color: #ffdede;
}

.new-group-users-table-tr {
  display: grid;
  grid-template-columns: 1fr 2fr 4fr 3fr 3fr 2fr 2fr;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  padding: 9px 0 9px 12px;
  width: 100%;
  min-height: 40px;
  text-align: left;
  word-break: break-all;
}

.new-group-users-table-tr th {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  text-align: left;
}

.new-group-users-table-tr th:last-of-type {
  margin: 0;
}

.new-group-users-table-tr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
}

.group-td-email {
  color: #5D5B5B;
}

.select-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 54px;
}

.button-delete {
  border: none;
  background: none;
  padding: 0;
  transition: all .2s;
}

.button-delete:disabled {
  opacity: 0.4;
}

.button-delete:not(:disabled) {
  opacity: 0.8;
  cursor: pointer;
}

.button-delete:not(:disabled):hover {
  opacity: 1;
  transform: scale(1.1);
}

.button-delete img {
  width: 24px;
  height: 24px;
}

.new-group-users-table-label::before {
  width: 11px;
  height: 8px;
  position: relative;
  top: 3px;
  margin-right: 5px;
  content: "";
}

.group-caption {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #A4A3A3;
}

.new-group-users-table-not-clickable button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.new-group-users-table-not-clickable button:hover {
  color: #9D1B1F
}

.new-group-users-table-not-clickable button.ascending::after {
  content: '\2193';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.new-group-users-table-not-clickable button.descending::after {
  content: '\2191';
  color: #9D1B1F;
  display: inline-block;
  margin-left: 2px;
}

.group-selected-bar {
  display: flex;
  margin: 8px 8px 8px 0;
  align-items: center;
}

.group-input-edit {
  max-width: 300px;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  padding: 9px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #343232;
  transition: all 0.1s linear;
  align-self: center;
}

.group-input-edit::placeholder {
  font-size: 0.9em;
  color: #A4A3A3;
}

.group-input-edit:hover:not(:disabled) {
  box-shadow: 0 0 3px 0.5px #D6D6D6;
}

.group-input-edit:focus:not(:disabled) {
  box-shadow: 0 0 3px 2px #D6D6D6;
  outline: none;
  border-color: #d3d3d3;
}

.group-input-edit:disabled {
  background: #F3F3F3;
  color: #A4A3A3;
}

.new-group-select {
  width: 100%;
  margin-bottom: 12px
 }

 .modal-warning {
  display: flex;
  gap: 4px;
  text-align: center;
  color: #ed9005;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
 }

