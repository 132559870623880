.report-schedules-container {
  padding: 10px 10px 0 0;
}

.no-report-schedules-message {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.report-schedules-table {
  position: relative;
  display: grid;
  border-collapse: collapse;
  min-width: 740px;
  width: 100%;
  grid-template-columns:
    minmax(180px, 1.5fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    90px
    100px
    100px
    90px;
  border-top: none;
  border-radius: 4px;
}

.table-th-report-schedules {
  display: contents;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #343232;
}

.tr-report-schedules th:nth-of-type(1),
.tr-report-schedules th:nth-of-type(2),
.tr-report-schedules td:nth-of-type(2),
.tr-report-schedules th:nth-of-type(3),
.tr-report-schedules td:nth-of-type(3),
.tr-report-schedules th:nth-of-type(4),
.tr-report-schedules td:nth-of-type(4),
.tr-report-schedules th:nth-of-type(5),
.tr-report-schedules td:nth-of-type(5) {
  justify-content: flex-start;
  text-align: left;
  align-items: center;
}

.table-th-report-schedules,
.table-body-report-schedules,
.tr-grid-report-schedules
 {
  display: contents;
}

.tr-grid-report-schedules td,
.tr-grid-report-schedules-users td {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 4px 10px;
  color: #343232;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;

  border-bottom: 1px solid #00000026;
}

.list-td-report-schedules-div {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tr-grid-report-schedules th,
.tr-grid-report-schedules-users th
{
  padding: 12px 10px;
  text-align: center;
  border-bottom: 1px solid #00000026;
  z-index: 4;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  background: #FCFCFD;
}

.tr-grid-report-schedules th {
  border-top: 1px solid #00000026;
}

.tr-report-schedules {
  background-color: #00000026;
  width: fit-content;
}

.list-td-report-schedules {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  color: #5D5B5B;
  transition: background-color .2s ease-in-out;
}

.list-td-report-schedules:nth-child(-n+5) {
  justify-content: flex-start;
}

.list-td-report-schedules:nth-child(1) {
  padding-top: 20px;
}

.list-td-report-schedules-div-span {
  display: flex;
  color: #858484;
  line-height: 16px;
  font-size: 11px;
  justify-content: flex-start;
}
