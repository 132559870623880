@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Inter', sans-serif;
  color: #190606;
  background: #E6E6E6;
}

body,
h1,
ul,
section,
menu,
switch {
  margin: 0;
  padding: 0;
  border: 0;
}

.container:not(:has(.unauthorized)) {
  position: relative;
  margin: auto;
  max-width: 1920px;
}

table {
  background: #fff;
}

::-webkit-scrollbar-track
{
	background-color: #fff;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #fff;
}

::-webkit-scrollbar-thumb
{
	border-radius: 4px;
	background-color: #E6E6E6;
}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 5px #a4a4a4;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 12px;
  height: 12px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 6px;
	background-color: #a4a4a4;
}

.tooltip-help {
  cursor: help !important;
}

::selection {
  color: #f0f0f0;
  background-color: #9D1B1F;
}

.loading {
  cursor: progress !important;
}

.input-field-tenant-csv::before {
  content: url('./images/icons/File\ Red.svg');
  top: 9px;
}
.input-field-tenant-google::before {
  content: url('./images/icons/Google\ \(colors\).svg');
  top: 9px;
}
.input-field-tenant-azure::before {
  content: url('./images/icons/Azure.svg');
  top: 9px;
}

.download-more-list-button {
  position: relative;
  width: 150px;
  height: 36px;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #B72025;
  border-radius: 4px;
  border: transparent;
  cursor: pointer;
  transition: all 0.2s;
}

.download-more-list-button:not(:hover)::after {
  content: 'Download more';
  animation: push-opacity-bottom .2s;
}
.download-more-list-button:hover::after {
  content: url('./images/icons/arrows.svg');
  display: block;
  width: 26px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  position: absolute;
  animation: push-opacity-top .2s;
}

.download-more-list-button:hover {
  background-color: #9D1B1F;
}

.download-more-list-button:active {
  background-color: #9D1B1F;
  transform: translateY(3px);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: 100;
  overflow: auto;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Overlay--after-close{
  opacity: 0;
}
